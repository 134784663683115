// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rS_v80pc1MT1v1p2txMz {
  padding-top: 0.5rem;
}

.QAxwStggA10Y_UgRahOK {
  margin: 40px 0px;
}

.tVooZMyKPPMdP2deWE7U {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.cqTogw2uP5yg13uIOCjm {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.i42_9ZUQ_cC_K0zg1PrQ {
  font-family: Poppins, sans-serif;
  background-color: #ffffff;
  color: #2755fe;
  border: 1px solid #2755fe;
}

.yg_gKCfr7GmO37oohjSJ {
  background-color: #037bff;
  color: white;
  padding: 0.5rem;
  border: none;
}

.thCGXiPZbxn5wviIrlcV {
  background-color: #1bae9f;
  color: white;
  padding: 0.5rem;
  border: none;
}

.yg_gKCfr7GmO37oohjSJ:hover {
  background-color: #076dda;
}

.thCGXiPZbxn5wviIrlcV:hover {
  background-color: #11a294;
}

.dB0Z7YspJjEVpWbXMWrj {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}

.lfKEo9LKdMfejpVCfVnI {
  border: 1px solid red;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/Integrations/HROne/InputArea/InputArea.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AACA;EACE,gBAAA;AAEF;;AAAA;EACE,eAAA;EACA,WAAA;EACA,mBAAA;AAGF;;AADA;EACE,aAAA;EACA,2BAAA;EACA,SAAA;EACA,gBAAA;EACA,mBAAA;AAIF;;AAFA;EACE,gCAAA;EACA,yBAAA;EACA,cAAA;EACA,yBAAA;AAKF;;AAFA;EACE,yBAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;AAKF;;AAHA;EACE,yBAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;AAMF;;AAJA;EACE,yBAAA;AAOF;;AALA;EACE,yBAAA;AAQF;;AALA;EACE,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAQF;;AANA;EACE,qBAAA;AASF","sourcesContent":[".inputLabel {\n  padding-top: 0.5rem;\n}\n.generateTokenButton {\n  margin: 40px 0px;\n}\n.generateTokenButton__heading {\n  font-size: 16px;\n  color: #333;\n  margin-bottom: 10px;\n}\n.step1FooterContainer {\n  display: flex;\n  justify-content: flex-start;\n  gap: 1rem;\n  margin-top: 1rem;\n  margin-bottom: 2rem;\n}\n.goBackBtn {\n  font-family: Poppins, sans-serif;\n  background-color: #ffffff;\n  color: #2755fe;\n  border: 1px solid #2755fe;\n}\n\n.connectButton {\n  background-color: #037bff;\n  color: white;\n  padding: 0.5rem;\n  border: none;\n}\n.connectedButton {\n  background-color: #1bae9f;\n  color: white;\n  padding: 0.5rem;\n  border: none;\n}\n.connectButton:hover {\n  background-color: #076dda;\n}\n.connectedButton:hover {\n  background-color: #11a294;\n}\n\n.listItems {\n  font-family: 'Poppins';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 24px;\n  color: #333333;\n}\n.removeButton {\n  border: 1px solid red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputLabel": `rS_v80pc1MT1v1p2txMz`,
	"generateTokenButton": `QAxwStggA10Y_UgRahOK`,
	"generateTokenButton__heading": `tVooZMyKPPMdP2deWE7U`,
	"step1FooterContainer": `cqTogw2uP5yg13uIOCjm`,
	"goBackBtn": `i42_9ZUQ_cC_K0zg1PrQ`,
	"connectButton": `yg_gKCfr7GmO37oohjSJ`,
	"connectedButton": `thCGXiPZbxn5wviIrlcV`,
	"listItems": `dB0Z7YspJjEVpWbXMWrj`,
	"removeButton": `lfKEo9LKdMfejpVCfVnI`
};
export default ___CSS_LOADER_EXPORT___;
