// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: Poppins, sans-serif;
}

.rdBQ982nMHFuD9WjUtSp {
  margin-top: 20px;
}

.xFyANb3K9RPmb6ueKx8B {
  color: #f5222d;
}

.E6uE12dsbAZPv7nXRbhu {
  background: #f5f7ff;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  text-align: center;
  height: 117px;
  justify-content: center;
  margin-bottom: 16px !important;
  cursor: pointer;
}
.E6uE12dsbAZPv7nXRbhu p {
  font-weight: normal !important;
  margin: 0;
}

._dvcZxRC2bm3fddTqEHU {
  padding: 0;
}
._dvcZxRC2bm3fddTqEHU .d076fQQsxJiM3qyFl66K {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  color: #2755fe;
  padding: 8px 18px;
  margin-bottom: 16px;
  text-align: center;
  border: none;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  margin-left: 12px;
}
._dvcZxRC2bm3fddTqEHU .d076fQQsxJiM3qyFl66K:active {
  background: #ffffff !important;
  color: #2755fe !important;
  border: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Bgv/BgvBodyContainer/BgvStepBodyContianer/Address/AddressModals/AddressStep3/MultiUploadForm.module.scss"],"names":[],"mappings":"AAEA;EACI,gCAAA;AADJ;;AAGA;EACI,gBAAA;AAAJ;;AAEA;EACI,cAAA;AACJ;;AACA;EACI,mBAAA;EACA,0BAAA;EACA,kBAAA;EACA,aAAA;EACA,eAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,8BAAA;EACA,eAAA;AAEJ;AAAI;EACI,8BAAA;EACA,SAAA;AAER;;AACA;EACI,UAAA;AAEJ;AAAI;EACI,mBAAA;EACA,4CAAA;EACA,kBAAA;EACA,cAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAER;AAAQ;EACI,8BAAA;EACA,yBAAA;EACA,uBAAA;AAEZ","sourcesContent":["@import '/src/styles/mixins/breakpoints';\n\n* {\n    font-family: Poppins, sans-serif;\n}\n.Row {\n    margin-top: 20px;\n}\n.maxFileError {\n    color: #f5222d;\n}\n.certificateInputFileContainer {\n    background: #f5f7ff;\n    border: 1px dashed #d9d9d9;\n    border-radius: 2px;\n    display: flex;\n    padding: 0 10px;\n    flex-direction: column;\n    align-items: center;\n    font-size: 14px;\n    text-align: center;\n    height: 117px;\n    justify-content: center;\n    margin-bottom: 16px !important;\n    cursor: pointer;\n\n    p {\n        font-weight: normal !important;\n        margin: 0;\n    }\n}\n.takePhotoBtnContainer {\n    padding: 0;\n\n    .takePhotoBtn {\n        background: #ffffff;\n        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);\n        border-radius: 2px;\n        color: #2755fe;\n        padding: 8px 18px;\n        margin-bottom: 16px;\n        text-align: center;\n        border: none;\n        width: 100%;\n        font-size: 14px;\n        font-weight: 500;\n        margin-left: 12px;\n\n        &:active {\n            background: #ffffff !important;\n            color: #2755fe !important;\n            border: none !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Row": `rdBQ982nMHFuD9WjUtSp`,
	"maxFileError": `xFyANb3K9RPmb6ueKx8B`,
	"certificateInputFileContainer": `E6uE12dsbAZPv7nXRbhu`,
	"takePhotoBtnContainer": `_dvcZxRC2bm3fddTqEHU`,
	"takePhotoBtn": `d076fQQsxJiM3qyFl66K`
};
export default ___CSS_LOADER_EXPORT___;
