// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xrBq0XQVwidDctGls6YA {
  width: 1200px;
  background-color: white;
  box-shadow: 0px 2px 12px 0px rgba(51, 51, 51, 0.1);
  flex-shrink: 0;
  margin: 15px auto 0 auto;
}

.n8cDF7gvSRou1TmjOvgV {
  flex-shrink: 0;
  width: 1223px;
  margin: 0 auto 20px auto;
}

.XwIQDG1kpiJgdMyV5JfA {
  padding: 16px 0 16px 24px;
  border-bottom: 2px solid #f5f5f5;
  display: flex;
  color: #464457;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
}

.GO_5mo2t2mx4ic6y5cbv {
  margin: -2px 0px 0px 8px;
}

.CnHPqs7xOlrDdvsG9Rub {
  display: flex;
  padding: 16px 32px 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/UpsellServices/UpsellServices.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,kDAAA;EACA,cAAA;EACA,wBAAA;AACJ;;AACA;EACK,cAAA;EACC,aAAA;EACD,wBAAA;AAEL;;AAAA;EACM,yBAAA;EACA,gCAAA;EACA,aAAA;EACA,cAAA;EACA,oBAAA;EACA,eAAA;EACA,gBAAA;AAGN;;AAAA;EACK,wBAAA;AAGL;;AAAC;EACC,aAAA;EACA,uBAAA;AAGF","sourcesContent":[".wrapper {\n    width: 1200px;\n    background-color: white;\n    box-shadow: 0px 2px 12px 0px rgba(51, 51, 51, 0.1);\n    flex-shrink: 0;\n    margin: 15px auto 0 auto;\n}\n.marketingBanner{\n     flex-shrink: 0;\n      width: 1223px;\n     margin: 0 auto 20px auto;\n}\n.header {\n      padding: 16px 0 16px 24px;\n      border-bottom: 2px solid #f5f5f5;\n      display: flex;\n      color: #464457;\n      font-family: Poppins;\n      font-size: 16px;\n      font-weight: 500\n}\n\n.newTag {\n     margin: -2px 0px 0px 8px;\n }\n\n .cardContainer{\n  display: flex;\n  padding: 16px 32px 24px;\n }\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `xrBq0XQVwidDctGls6YA`,
	"marketingBanner": `n8cDF7gvSRou1TmjOvgV`,
	"header": `XwIQDG1kpiJgdMyV5JfA`,
	"newTag": `GO_5mo2t2mx4ic6y5cbv`,
	"cardContainer": `CnHPqs7xOlrDdvsG9Rub`
};
export default ___CSS_LOADER_EXPORT___;
